<template>
    <div>
        <sav-template>
            <div class="grid grid-cols-2 gap-4 mt-28 container mx-auto">
                <div>
                    <!-- International -->
                    <div class="mb-4 bg-slate-50 rounded p-4 border">
                        <div class="font-bold text-lg">International</div>
                        <div class="font-light text-sm mb-4 pb-4 border-b-2">Dans combien de pays travaillez-vous ?</div>
                        
                        <!-- Input -->
                        <div v-if="offerOptions" class="mb-4 bg-white p-3 border rounded mt-2">
                            <div>
                                <label class="text-sm">Nombre de pays</label>
                                <input class="border py-2 px-4 rounded w-full text-sm mt-1" type="number" v-model="nbPays" @input="() => {offerOptions.find((item) => item.keyword == 'international').total = nbPays * offerOptions.find((item) => item.keyword == 'international').value; offerOptions.find((item) => item.keyword == 'international').nbPays = nbPays}" />
                            </div>
                            <div class="font-light text-xs mt-2">{{ offerOptions.find((item) => item.keyword == 'international').value }} € de frais de mise en production par pays</div>
                        </div>
                    </div>

                    <!-- Autre options -->
                    <div v-for="(offerOption, index) in offerOptions" :key="'offer-option-' + index">
                        <div v-if="offerOption.type != 'international'" class="mb-4 bg-slate-50 rounded p-4 border">
                            <div class="font-bold text-lg">{{ offerOption.label }}</div>
                            <div class="font-light text-sm mb-4 pb-4 border-b-2">{{ offerDesc[offerOption.keyword] }}</div>
                            
                            <!-- Connexions -->
                            <div class="mb-4 bg-white p-3 border rounded mt-2" v-if="offerOption.type == 'connections'">
                                <div>
                                    <label class="text-sm">Nombre de connexion</label>
                                    <input class="border py-2 px-4 rounded w-full text-sm mt-1" type="number" v-model="offerOption.nbConnexions" @input="() => {offerOption.total = offerOption.nbConnexions * offerOption.value}" />
                                </div>
                                <div class="font-light text-xs mt-2">{{ offerOption.value }} € par lot de 300 dossiers par partenaire</div>
                            </div>
                            
                            <!-- Fixe -->
                            <div v-if="offerOption.type == 'fixe' && offerOption.actif">
                                <div class="text-emerald-600 font-bold">
                                    <i class="fas fa-check mr-1"></i> Activé
                                </div>
                            </div>

                            <!-- Licences -->
                            <div class="mb-4 bg-white p-3 border rounded mt-2" v-if="offerOption.type == 'users' && offerOption.actif">
                                <div>
                                    <label class="text-sm">Nombre d'utilisateur</label>
                                    <input class="border py-2 px-4 rounded w-full text-sm mt-1" type="number" v-model="offerOption.nbLicence" @input="() => {offerOption.total = offerOption.nbLicence * offerOption.value}" />
                                </div>
                                <div class="font-light text-xs mt-2">{{ offerOption.value }} € par utilisateur</div>
                            </div>

                            <!-- Pieces detachees -->
                            <div class="mb-4 bg-white p-3 border rounded mt-2" v-if="offerOption.keyword == 'pieces_detachees' && offerOption.actif">
                                <div>
                                    <label class="text-sm">Chiffre d'affaire de vente des pièces détachées par mois</label>
                                    <input class="border py-2 px-4 rounded w-full text-sm mt-1" type="number" v-model="offerOption.nbLicence" @input="() => {offerOption.total = (offerOption.nbLicence * (offerOption.value / 100)).toFixed(2)}" />
                                </div>
                                <div class="font-light text-xs mt-2">{{ offerOption.value }} % du CA</div>
                            </div>

                            <!-- Active button -->
                            <div v-if="!offerOption.actif">
                                <button class="mt-1 px-4 py-2 bg-emerald-600 text-white rounded text-sm" @click="offerOption.actif = !offerOption.actif">
                                    Activer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <template v-if="amountTotal > 0">
                        <h2 class="text-2xl font-base">Récapitulatif</h2>
                        <div v-for="(offerOption, index) in offerOptions" :key="'recap-option-' + index">
                            <div class="border-t mt-3 pt-3" v-if="offerOption.actif">
                                <div class="flex items-center gap-4">
                                    <button @click="offerOption.actif = false"><i class="fas fa-trash text-red-500"></i></button>
                                    <div>
                                        <div class="font-bold">{{ offerOption.label }}</div>
                                        {{ offerOption.total }} €
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-t mt-3 pt-3">
                            <div class="bg-yellow-100 p-4 rounded my-2 text-yellow-600 text-sm"><i class="fas fa-exclamation-triangle mr-1"></i> Ce prix est une estimation</div>
                            <div class="mt-4 text-xl">Total: {{ amountTotal }} € / mois</div>
                            <div class="font-light">Frais de mise en production: {{ offerOptions.find((item) => item.keyword == 'international').total }} €</div>
                        </div>
                    </template>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    watch: {
        offerOptions: {
            deep: true,
            handler() {
                this.saveSimulation()
            }
        }
    },
    data() {
        return {
            offerOptions: [],
            offerDesc: {
                'connection': "Avec combien de partenaires souhaitez-vous vous connecter ?",
                'stats': "Statistiques avancées, Power BI",
                'callcenter': "Plateforme de gestion SAV pour call center",
                'selfcare': "Application web pour permettre aux consommateurs finaux de gérer leur SAV en autonomie",
                'pieces_detachees': "Gestion des pièces détachées"
            },

            nbPays: 1,
        }
    },
    computed: {
        amountTotal() {
            let actifs = this.offerOptions.filter((item) => item.actif)
            return actifs.reduce((accumulator, object) => {
                return Number(accumulator) + Number(object.total)
            }, 0);

        }
    },
    methods: {
        async init() {
            let tokenExists = await apiService.post('vitrine.simulation-check-token', {
                user_token: this.$route.params.token,
                no_user: 1               
            })

            if (tokenExists.data.posts.post == 0) {
                this.$toast.error('Url invalide')
                this.$router.push({name: 'home'})
                return
            }

            let responseSimulation = await apiService.post('vitrine.devis.get-simulation', {
                user_token: this.$route.params.token,
                no_user: 1
            })
            if (responseSimulation.data.posts.post.conf) {
                this.offerOptions = JSON.parse(responseSimulation.data.posts.post.conf)
            }
            if (!this.offerOptions || this.offerOptions.length <= 0) {
                let response = await apiService.post('vitrine.get-price-options', {
                    no_user: 1
                })
                this.offerOptions = response.data.posts.post.map((item) => ({
                    ...item,
                    actif: item.keyword == 'connection' ? 1 : 0,
                    nbConnexions: 0,
                    nbLicence: 0,
                    nbPays: 0,
                    nbDossiers: [],
                    total: item.type == 'fixe' ? item.value : 0
                }))
                this.offerOptions.find((item) => item.keyword == 'international').total = 1 * this.offerOptions.find((item) => item.keyword == 'international').value
            }
        },

        async saveSimulation() {
            await apiService.post('vitrine.devis.simulation', {
                user_token: this.$route.params.token,
                conf: JSON.stringify(this.offerOptions),
                no_user: 1
            })
        }
    },
    mounted () {
        this.init()
    },
}
</script>

<style lang="scss" scoped>

</style>